import { intervalToDuration } from 'date-fns';
import { getDuration } from './getDuration';
import { WorkAvailabilityEnum } from '../../../graphql/generated';
import i18next from 'i18next';

// handles cases like
// employed until 20.10.2024
// employed until bis 17.09.2026 (1 Year)
// available as of 20.10.2024
// available as 17.12.2024 (3 Months)

export function getAvailabilityInfo(
  workAvailability: WorkAvailabilityEnum,
  availableForWorkAt: string | null,
  employedUntil: string | null,
  formatDate: (date: Date) => string,
): string {
  if (workAvailability === WorkAvailabilityEnum.ACTIVE && availableForWorkAt) {
    return formatAvailabilityInfo(
      availableForWorkAt,
      'asOf',
      'talentEmployedFrom',
      formatDate,
    );
  }

  if (
    workAvailability === WorkAvailabilityEnum.IN_EMPLOYMENT &&
    employedUntil
  ) {
    return formatAvailabilityInfo(
      employedUntil,
      'until',
      'talentEmployedUntil',
      formatDate,
    );
  }

  return '';
}

function getAvailabilityStatusInfo(dateInput: string) {
  const date = new Date(dateInput);
  const now = new Date();
  const durationSincePublished = intervalToDuration({
    start: date,
    end: now,
  });
  const { duration, durationUnit } = getDuration(durationSincePublished);
  if (now < date) {
    return {
      date,
      duration,
      durationUnit,
    };
  }
  return {
    date,
  };
}

function formatAvailabilityInfo(
  date: string,
  simpleKey: string,
  complexKey: string,
  formatDate: (date: Date) => string,
): string {
  const {
    date: availabilityDate,
    duration,
    durationUnit,
  } = getAvailabilityStatusInfo(date);
  const formattedDate = formatDate(availabilityDate);

  if (!duration) {
    return `${i18next.t(simpleKey)} ${formattedDate}`;
  }

  return i18next.t(complexKey, {
    date: formattedDate,
    duration,
    durationUnit: i18next.t(durationUnit),
  });
}
