import {
  SortDirEnum,
  TalentCriteriaItemFragment,
  TalentSortEnum,
  TalentTableColumnIdEnum,
} from '../../../graphql/generated';
import { ColumnSortKeys, TalentColumnTitles } from '../talent.types';
import { TalentsListStateColumnConfiguration } from '../../../state/talents-list/talents-list-state.types';

// Settings definitions

export const initialTalentTableConfiguration: ReadonlyArray<TalentsListStateColumnConfiguration> =
  [
    {
      columnId: TalentTableColumnIdEnum.FIRST_NAME,
      visible: true,
      width: 140,
    },
    {
      columnId: TalentTableColumnIdEnum.LAST_NAME,
      visible: true,
      width: 170,
    },
    {
      columnId: TalentTableColumnIdEnum.TALENT_NUMBER,
      visible: true,
      width: 120,
    },
    {
      columnId: TalentTableColumnIdEnum.WORK_AVAILABILITY,
      visible: true,
      width: 220,
    },
    {
      columnId: TalentTableColumnIdEnum.STATUS,
      visible: true,
      width: 220,
    },
    {
      columnId: TalentTableColumnIdEnum.PRIMARY_PHONE,
      visible: true,
      width: 240,
    },
    {
      columnId: TalentTableColumnIdEnum.PRIMARY_EMAIL,
      visible: true,
      width: 280,
    },
    {
      columnId: TalentTableColumnIdEnum.TARGET_JOB_TITLE,
      visible: true,
      width: 220,
    },
    {
      columnId: TalentTableColumnIdEnum.PREFERRED_EMPLOYMENT_TYPE,
      visible: false,
      width: 220,
    },
    {
      columnId: TalentTableColumnIdEnum.WORKLOAD,
      visible: true,
      width: 220,
    },
    {
      columnId: TalentTableColumnIdEnum.SALARY_EXPECTATION,
      visible: true,
      width: 220,
    },
    {
      columnId: TalentTableColumnIdEnum.UPDATED_AT,
      visible: true,
      width: 220,
    },
    {
      columnId: TalentTableColumnIdEnum.CREATED_AT,
      visible: true,
      width: 220,
    },
  ];

/**
 * Defines the sort keys belonging to a column, if one exists and the table should be sortable by this column
 */
export const sortableTableColumns: Partial<ColumnSortKeys> = {
  // currently active
  [TalentTableColumnIdEnum.FIRST_NAME]: TalentSortEnum.FIRST_NAME,
  [TalentTableColumnIdEnum.LAST_NAME]: TalentSortEnum.LAST_NAME,
  [TalentTableColumnIdEnum.TALENT_NUMBER]: TalentSortEnum.TALENT_NUMBER,
  [TalentTableColumnIdEnum.WORK_AVAILABILITY]: TalentSortEnum.WORK_AVAILABILITY,
  [TalentTableColumnIdEnum.STATUS]: TalentSortEnum.STATUS,
  [TalentTableColumnIdEnum.PRIMARY_PHONE]: TalentSortEnum.PRIMARY_PHONE,
  [TalentTableColumnIdEnum.PRIMARY_EMAIL]: TalentSortEnum.PRIMARY_EMAIL,
  [TalentTableColumnIdEnum.TARGET_JOB_TITLE]: TalentSortEnum.TARGET_JOB_TITLE,
  [TalentTableColumnIdEnum.PREFERRED_EMPLOYMENT_TYPE]:
    TalentSortEnum.PREFERRED_EMPLOYMENT_TYPE,
  [TalentTableColumnIdEnum.WORKLOAD]: TalentSortEnum.WORKLOAD,
  [TalentTableColumnIdEnum.SALARY_EXPECTATION]:
    TalentSortEnum.SALARY_EXPECTATION,
  [TalentTableColumnIdEnum.UPDATED_AT]: TalentSortEnum.UPDATED_AT,
  [TalentTableColumnIdEnum.CREATED_AT]: TalentSortEnum.CREATED_AT,

  // future
  // [TalentTableColumnIdEnum.UUID]: TalentSortEnum.UUID,
  // [TalentTableColumnIdEnum.DATE_OF_BIRTH]: TalentSortEnum.DATE_OF_BIRTH,
  // [TalentTableColumnIdEnum.PLACE_OF_BIRTH]: TalentSortEnum.PLACE_OF_BIRTH,
  // [TalentTableColumnIdEnum.NATIONALITY]: TalentSortEnum.NATIONALITY,
  // [TalentTableColumnIdEnum.CIVIL_ORIGIN]: TalentSortEnum.CIVIL_ORIGIN,
  // [TalentTableColumnIdEnum.RESIDENCE_PERMIT]: TalentSortEnum.RESIDENCE_PERMIT,
  // [TalentTableColumnIdEnum.RESIDENCE_PERMIT_VALID_UNTIL]:
  //   TalentSortEnum.RESIDENCE_PERMIT_VALID_UNTIL,
  // [TalentTableColumnIdEnum.DRIVERS_LICENSE]: TalentSortEnum.DRIVERS_LICENSE,
  // [TalentTableColumnIdEnum.RELIGIOUS_DENOMINATION]:
  //   TalentSortEnum.RELIGIOUS_DENOMINATION,
  // [TalentTableColumnIdEnum.MARITAL_STATUS]: TalentSortEnum.MARITAL_STATUS,
  // [TalentTableColumnIdEnum.AHV_NUMBER]: TalentSortEnum.AHV_NUMBER,
  // [TalentTableColumnIdEnum.IBAN]: TalentSortEnum.IBAN,
  // [TalentTableColumnIdEnum.PRIMARY_ADDRESS_STREET]:
  //   TalentSortEnum.PRIMARY_ADDRESS_STREET,
  // [TalentTableColumnIdEnum.PRIMARY_ADDRESS_ZIP]:
  //   TalentSortEnum.PRIMARY_ADDRESS_ZIP,
  // [TalentTableColumnIdEnum.PRIMARY_ADDRESS_LOCATION]:
  //   TalentSortEnum.PRIMARY_ADDRESS_LOCATION,
  // [TalentTableColumnIdEnum.AVAILABLE_FOR_WORK_AT]:
  //   TalentSortEnum.AVAILABLE_FOR_WORK_AT,
  // [TalentTableColumnIdEnum.REGISTERED_WITH_RAV]:
  //   TalentSortEnum.REGISTERED_WITH_RAV,
  // [TalentTableColumnIdEnum.NON_COMPETE_AGREEMENT]:
  //   TalentSortEnum.NON_COMPETE_AGREEMENT,
  // [TalentTableColumnIdEnum.MUST_SERVE_MILITARY_SERVICE]:
  //   TalentSortEnum.MUST_SERVE_MILITARY_SERVICE,
  // [TalentTableColumnIdEnum.NEXT_MILITARY_SERVICE]:
  //   TalentSortEnum.NEXT_MILITARY_SERVICE,
  // [TalentTableColumnIdEnum.HAS_ALLOWED_DATA_USAGE_AT]:
  //   TalentSortEnum.HAS_ALLOWED_DATA_USAGE_AT,
  // [TalentTableColumnIdEnum.PREFERRED_WORK_ARRANGEMENT]:
  //   TalentSortEnum.PREFERRED_WORK_ARRANGEMENT,
  // [TalentTableColumnIdEnum.PREFERRED_LOCATION]:
  //   TalentSortEnum.PREFERRED_LOCATION,
  // [TalentTableColumnIdEnum.PREFERRED_LOCATION_RADIUS_KM]:
  //   TalentSortEnum.PREFERRED_LOCATION_RADIUS_KM,
  // [TalentTableColumnIdEnum.PREFERRED_MAX_COMMUTING_TIME_MINUTES]:
  //   TalentSortEnum.PREFERRED_MAX_COMMUTING_TIME_MINUTES,
  // [TalentTableColumnIdEnum.PREFERS_COMMUTING_BY_BICYCLE]:
  //   TalentSortEnum.PREFERS_COMMUTING_BY_BICYCLE,
  // [TalentTableColumnIdEnum.PREFERS_COMMUTING_BY_CAR]:
  //   TalentSortEnum.PREFERS_COMMUTING_BY_CAR,
  // [TalentTableColumnIdEnum.PREFERS_COMMUTING_BY_PUBLIC_TRANSPORT]:
  //   TalentSortEnum.PREFERS_COMMUTING_BY_PUBLIC_TRANSPORT,
};

export const columnTitles: TalentColumnTitles = {
  // currently active
  [TalentTableColumnIdEnum.FIRST_NAME]: 'firstName',
  [TalentTableColumnIdEnum.LAST_NAME]: 'lastName',
  [TalentTableColumnIdEnum.TALENT_NUMBER]: 'talentNumber',
  [TalentTableColumnIdEnum.WORK_AVAILABILITY]: 'workAvailability',
  [TalentTableColumnIdEnum.STATUS]: 'status',
  [TalentTableColumnIdEnum.PRIMARY_PHONE]: 'phone',
  [TalentTableColumnIdEnum.PRIMARY_EMAIL]: 'email',
  [TalentTableColumnIdEnum.TARGET_JOB_TITLE]: 'targetJobTitle',
  [TalentTableColumnIdEnum.PREFERRED_EMPLOYMENT_TYPE]:
    'preferredEmploymentType',
  [TalentTableColumnIdEnum.WORKLOAD]: 'workload',
  [TalentTableColumnIdEnum.SALARY_EXPECTATION]: 'salaryExpectation',
  [TalentTableColumnIdEnum.UPDATED_AT]: 'lastUpdated',
  [TalentTableColumnIdEnum.CREATED_AT]: 'createdAt',

  // future
  // [TalentTableColumnIdEnum.UUID]: 'referenceNumber',
  // [TalentTableColumnIdEnum.DATE_OF_BIRTH]: 'dateOfBirth',
  // [TalentTableColumnIdEnum.PLACE_OF_BIRTH]: 'placeOfBirth',
  // [TalentTableColumnIdEnum.NATIONALITY]: 'nationality',
  // [TalentTableColumnIdEnum.CIVIL_ORIGIN]: 'civilOrigin',
  // [TalentTableColumnIdEnum.RESIDENCE_PERMIT]: 'residencePermit',
  // [TalentTableColumnIdEnum.RESIDENCE_PERMIT_VALID_UNTIL]:
  //   'residencePermitValidUntil',
  // [TalentTableColumnIdEnum.DRIVERS_LICENSE]: 'driversLicense',
  // [TalentTableColumnIdEnum.RELIGIOUS_DENOMINATION]: 'religiousDenomination',
  // [TalentTableColumnIdEnum.MARITAL_STATUS]: 'maritalStatus',
  // [TalentTableColumnIdEnum.AHV_NUMBER]: 'ahvNumber',
  // [TalentTableColumnIdEnum.IBAN]: 'iban',
  // [TalentTableColumnIdEnum.PRIMARY_ADDRESS_STREET]: 'primaryAddressStreet',
  // [TalentTableColumnIdEnum.PRIMARY_ADDRESS_ZIP]: 'primaryAddressZip',
  // [TalentTableColumnIdEnum.PRIMARY_ADDRESS_LOCATION]: 'primaryAddressLocation',
  // [TalentTableColumnIdEnum.AVAILABLE_FOR_WORK_AT]: 'availableForWorkAt',
  // [TalentTableColumnIdEnum.REGISTERED_WITH_RAV]: 'registeredWithRav',
  // [TalentTableColumnIdEnum.NON_COMPETE_AGREEMENT]: 'nonCompeteAgreement',
  // [TalentTableColumnIdEnum.MUST_SERVE_MILITARY_SERVICE]:
  //   'mustServeMilitaryService',
  // [TalentTableColumnIdEnum.NEXT_MILITARY_SERVICE]: 'nextMilitaryService',
  // [TalentTableColumnIdEnum.HAS_ALLOWED_DATA_USAGE_AT]: 'hasAllowedDataUsageAt',
  // [TalentTableColumnIdEnum.PREFERRED_WORK_ARRANGEMENT]:
  //   'preferredWorkArrangement',
  // [TalentTableColumnIdEnum.PREFERRED_LOCATION]: 'preferredLocation',
  // [TalentTableColumnIdEnum.PREFERRED_LOCATION_RADIUS_KM]:
  //   'preferredLocationRadiusKm',
  // [TalentTableColumnIdEnum.PREFERRED_MAX_COMMUTING_TIME_MINUTES]:
  //   'preferredMaxCommutingTimeMinutes',
  // [TalentTableColumnIdEnum.PREFERS_COMMUTING_BY_BICYCLE]:
  //   'prefersCommutingByBicycle',
  // [TalentTableColumnIdEnum.PREFERS_COMMUTING_BY_CAR]: 'prefersCommutingByCar',
  // [TalentTableColumnIdEnum.PREFERS_COMMUTING_BY_PUBLIC_TRANSPORT]:
  //   'prefersCommutingByPublicTransport',
};

export const TALENTS_DEFAULT_SORT_DIR = SortDirEnum.DESC;
export const TALENTS_DEFAULT_SORT_BY = TalentSortEnum.CREATED_AT;
export const TALENTS_DEFAULT_CRITERIA: readonly TalentCriteriaItemFragment[] =
  [];
