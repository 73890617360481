import { Component, computed, inject, input } from '@angular/core';
import { ButtonSolidComponent, ButtonTextComponent } from '@intemp/unijob-ui2';
import { I18NextModule } from 'angular-i18next';
import { NgIf } from '@angular/common';
import { TalentAllowedAction } from '../talent.types';
import { GlobalSheetsService } from '../../../shared/modules/global-sheets/global-sheets.service';
import { TalentsService } from '../../../core/services/talents/talents.service';
import { TalentFragment } from '../../../graphql/generated';

@Component({
  selector: 'app-talent-action-menu',
  templateUrl: './talent-action-menu.component.html',
  standalone: true,
  imports: [ButtonTextComponent, I18NextModule, NgIf, ButtonSolidComponent],
})
export class TalentActionMenuComponent {
  service = inject(TalentsService);
  globalSheetsService = inject(GlobalSheetsService);
  talent = input.required<TalentFragment>();
  buttonStyle = input<'solid' | 'text'>('text');
  isInSheet = input<boolean>(false);

  actions = computed(() => {
    const talent = this.talent();
    const { primaryActions, sheetActions, secondaryActions } =
      this.service.getTalentActionOptions(talent, this.isInSheet());

    return {
      primaryActions,
      secondaryActions,
      sheetActions,
    };
  });

  hasSeparator = computed(() => {
    const actions = this.actions();
    return (
      actions.primaryActions.length > 0 && actions.secondaryActions.length > 0
    );
  });

  handleActionClick(action: TalentAllowedAction) {
    if (action.action) {
      action.action();
    }
  }
}
