<div
  *ngIf="actions().primaryActions.length || actions().secondaryActions.length"
  class="uj-flex uj-flex-col uj-items-start">
  @for (action of actions().primaryActions; track action.label) {
    @if (buttonStyle() === "text") {
      <button
        [disabled]="action.disabled"
        fullWidth
        ui-library-button-text
        color="dark"
        align="start"
        [iconLeft]="action.icon"
        (click)="handleActionClick(action)">
        {{ action.shortLabel || action.label | i18next }}
      </button>
    } @else {
      <button
        ui-library-button-solid
        fullWidth
        align="start"
        [disabled]="action.disabled"
        [iconLeft]="action.icon"
        (click)="handleActionClick(action)">
        {{ action.shortLabel || action.label | i18next }}
      </button>
    }
  }
  <hr
    class="uj-mx-[24px] uj-my-b uj-bg-border uj-h-[1px] uj-w-[calc(100%-48px)]"
    *ngIf="hasSeparator()" />

  @for (action of actions().secondaryActions; track action.label) {
    @if (buttonStyle() === "text") {
      <button
        ui-library-button-text
        fullWidth
        align="start"
        [iconLeft]="action.icon"
        (click)="handleActionClick(action)">
        {{ action.shortLabel || action.label | i18next }}
      </button>
    } @else {
      <button
        ui-library-button-solid
        fullWidth
        align="start"
        [iconLeft]="action.icon"
        (click)="handleActionClick(action)">
        {{ action.shortLabel || action.label | i18next }}
      </button>
    }
  }
</div>
